
.a-footer-container{
    padding: 70px 80px 0px 80px;
}

.a-footer-container h1{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}

.a-team-img-container{
    max-width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background-color: #f5f5f5;
}

.a-team-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.a-team-card-container{
     display: flex;
     flex-wrap: wrap;
     padding-bottom: 70px;
     max-width: 1112px;
     gap: 110px;
     margin: 0 auto;
     justify-content: center;
}

.a-team-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.a-team-card h3{
    font-size: 20px;
    margin-top: 16px;
    font-weight: 700;
}

.a-team-card p{
    font-size: 20px;
    margin-top: 8px;
    text-align: center;
    font-weight: 500;
}


@media all and (max-width:768px){

    .a-team-img-container{
        height: auto;
    }

    .a-team-card-container {
            display: grid;
            gap: 50px;
            grid-template-columns: repeat(2, 1fr);
    }      
}

@media all and (max-width:530px){
    .a-team-card-container {
            grid-template-columns: repeat(1, 1fr);
        }
}

@media all and (max-width:450px){

}