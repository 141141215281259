

.footer-wrapper{
    background-image: url('../../../assets/commonFooterImg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.h-footer-container{
    color: white;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 100px;
    justify-content: space-around;
    gap: 30px;
    display: flex;
    min-height: 300px; 
}

.footer-hr{
    margin-top: 20px;
}


.child-container{
    display: flex;
    gap: 84px;
    width: 636px;
}

.h-footer-child{
    max-width: 250px;
}

.h-footer-sponsifyr{

    max-width: 340px;
}

.h-footer-child h2{
    font-size: 25px;
        margin-bottom: 25px;
        font-weight: 700;
}

.h-footer-sponsifyr h2{
    font-size: 25px;
        margin-bottom: 25px;
        font-weight: 700;
}


.h-footer-child h3{
    font-size: 25px;
    margin-bottom: 25px;
    max-width: 141px;
    font-weight: 700;  
    position: relative; 
}

.footer-bottom p{
    text-align: center;
}

.h-footer-child h3::before{
   content: '';
   position: absolute;
   background-color: white;
   height: 1px;
   width: 100%;
   bottom: -8px;
}

.h-footer-sponsifyr p{
    line-height: 27px;
    color: rgba(210, 209, 209, 1);
}

.h-footer-child ul li{
    list-style-type: none;
    margin-top: 5px;
}

.h-footer-child ul li a{
    color: rgba(210, 209, 209, 1);
    
    text-decoration: none;
}

.footer-bottom{
    color: white;
    display: flex;
    margin: 0 auto;
    max-width: 1150px;
    justify-content: space-between;
    padding: 26px 60px;
}

.progg{
    color: white;
    animation: colorChange 2s infinite;
    text-decoration: none;
    display: inline-block;
    margin-left: 2px;
    margin-top: 2px;
    color: #ca9953;
}

@keyframes colorChange {
    0% {
        color: #f39c12;
    }

    50% {
        color: #e74c3c;
    }

    100% {
        color: #f39c12;
    }
}


@media all and (max-width:1300px){
    .h-footer-container{
        padding: 100px 60px 0px 60px;
    }
}

@media all and (max-width:1020px){
    .child-container {

            gap: 40px;
            width: none;
        }
}


@media all and (max-width:900px){
    .child-container{
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
    } 
}

@media all and (max-width:768px){
   .h-footer-container{
    flex-direction: column;
   }

   .footer-bottom{
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
    gap: 20px;
   }

   .child-container {
       display: grid;
       grid-template-columns: repeat(2, 0fr);
       width: 100%;
   }

   .h-footer-sponsifyr{
     max-width: none;
     width: 100%;
   }
}

@media all and (max-width:450px){
    .h-footer-container{
        padding: 100px 30px 50px 30px;
        
    }

    .footer-bottom{
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    

    .child-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}


