

.choose-us-container{
  margin: 60px 0px;
}

.h-c-content-wrap{
    margin-top: 60px;
    max-width: 1280px;
    margin: 0 auto;
}

.choose-title{
  margin-bottom: 22px;
  font-weight: 500;
  position: relative;
  font-size: 20px;
}


.choose-us-container h3{
    font-weight: 700;
    font-size: 30px;
}

.choose-title::before{
    content: '';
    /* border-left: 1px solid var(--primary-color); */
    position: absolute;
    top: 10px;
    background-color: var(--primary-color);
    left: -30px;
    width: 42px;
    height: 2px;
    transform: rotate(90deg);
}

.choose-main{
    display: flex;
    gap: 16px;
    margin-top: 50px;
}

.choose-left-bottom{
   
    background-color: var(--primary-color);
    padding: 25px 0px;
    color: white;
}

.choose-left-bottom div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-left{
    width: calc(100% - 575px);
    /* position: relative; */
}

.choose-right{
    max-width: 575px;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    gap: 45px;
}

.h-choose-icon{
    width: 50px;
}

.choose-img{
    margin: 0 auto;
}

.h-choose-img-container{
    display: flex;
    justify-content: center;
}

.choose-left-bottom{
    display: flex;
    position: relative;
    z-index: 2;
    margin-top: -6px;
    justify-content: space-around;
}

.RightChild{
    display: flex;
    gap: 22px;
}


.RightChild-inside h5{
   font-size: 26px;
   font-weight: 600;
   margin-bottom: 10px;
}

.RightChild-inside p{
   font-size: 18px;
}

@media all and (max-width:1300px){

    .choose-us-container{
        padding: 0px 60px;
    }

    .choose-img{
        width: 90%;
    }

    .choose-left{
        width: 50%;
    }

    .choose-right{
        width: 50%;
    }
}

@media all and (max-width:1120px){
    .choose-left {
            width: 100%;
        }
    
    .choose-right {
        width: 100%;
    }

    .choose-main{
        flex-direction: column;
        gap: 40px;
    }
}


@media all and (max-width:450px){
    .choose-us-container {
            padding: 0px 30px;
    }

    .choose-us-container h3{
        font-weight: 600;
        font-size: 22px;
    }

    .bottom-section-left h2{
        font-size: 20px;
    }

    .RightChild-inside h5 {
        font-size: 22px;
    }

    .h-choose-icon {
        width: 50px;
    }

    .RightChild-inside p {
        font-size: 18px;
    }

    .RightChild{
        flex-direction: column;
    }

    .year-para{
        text-align: center;
        font-size: 13px;
    }

    .choose-left-bottom{
        padding: 20px 0px;
        gap: 8px;
    }
}