

.solution-section{
    /* margin: 70px 0px; */
    padding: 70px 0px;
}

.solution-section h1{
    font-size: 44px;
    text-align: center;
    font-weight: 700;
    margin: auto;
    margin-bottom: 50px;
    max-width: 770px;
    color: var(--primary-color);
}




.s-child-container{
   padding: 40px 0px;
   background-color: rgba(228, 233, 255, 1);
}

.s-child-container-inside{
   max-width: 1054px;
}


.s-child-container-inside{
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    max-width: 1054px;
    display: flex;
    flex-direction: row;
}

.s-child-left{
    max-width: 604px;
}


.s-child-left h2{
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 25px;
    font-weight: 700;
}

.s-child-left p{
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: 400;
}


.s-child-right{
    width: 300px;
}

.s-child-right img{
    width: 100%;
}


.to-reverse{
   display: flex;
   flex-direction: row-reverse;
}

.card-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }


@media all and (max-width:1300px){
    .s-child-container{
        padding: 40px 60px;
    }
}

@media all and (max-width:960px){
    .s-child-left {
        width: 50%;
    }

    .s-child-container-inside{
        gap: 40px;
    }
}

@media all and (max-width:768px){
    .s-child-left {
        max-width: none;
        width: 100%;
    }

    .solution-section h1{
        padding: 0px 60px;
    }
    
    .s-child-container-inside {
        flex-direction: column-reverse;
    }

    .s-child-container-inside{
        gap: 40px;
    }
}

@media all and (max-width:500px){
    .s-child-right {
            width: 100%;
        }
}

@media all and (max-width:450px){
    .solution-section h1 {
        padding: 0px 30px;
    }

    .s-child-container {
        padding: 40px 30px;
    }
        

  .solution-section h1 {
      font-size: 24px;
  }
  .s-child-left h2{
    font-size: 24px;
    margin-bottom: 12px;
  }
  .s-child-left p {
      font-size: 18px;
      
  }
}
