
.nav-wrapper{
   background-color: #eee4ff;
   width: 100%;
box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.75);
    position: fixed;
   transition: top .333s;
top: 0px;
   z-index: 999999;

}

.nav-shadow{
   box-shadow: 0px -4px 8px -1px rgba(0, 0, 0, 0.75);
   top: -100px;
   
}

nav{
    display: flex;
    justify-content: space-between;  
    padding: 20px 0px; 
    align-items: center;
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    
}

.Navbar-list{
    display: flex;
    transition: all 500ms ease-in-out;
    gap: 40px;
}

.Navbar-list-link{
    text-decoration: none;
    position: relative;
    letter-spacing: 1px;
    color: var(--primary-color);
    transition: all 250ms;
    font-weight: 500;
}

.Navbar-list-link::after{
        content: '';
        position: absolute;
        top: 28px;
        width: 0%;
        transition: all 250ms;
        right: 0px;
        border-top: 2px solid var(--primary-color);
        height: 2px;
}  

.Navbar-list-link::before{
        content: '';
        position: absolute;
        top: 28px;
        width: 0%;
        transition: all 250ms;
        left: 0px;
        border-top: 2px solid var(--primary-color);
        height: 2px;
}  

.Navbar-list-link:hover::after{
        width: 50%;
}

.Navbar-list-link:hover::before{
        width: 50%;
}

.Navbar-list-active{
    font-weight: 600
}

.Navbar-list-active::before{
    content: '';
    position: absolute;
    top: 28px;
    width: 100%;
    bottom: 1px;
    left: 0px;
    height: 2px;
    border-top: 2px solid var(--primary-color);
}

.Navbar-list li{
    list-style-type: none;
   
}

.Navbar-list li a:hover{
   opacity: 0.5;
}



.nav-btn{
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: var(--primary-color);
}




@media all and (max-width:1300px) {
    nav{
        padding: 20px 60px;
    }
}

@media all and (max-width:768px){
    .nav-btn {
        display: flex;
        
    }

    .Navbar-list{
            border-top: 1px solid #d2d4df;;
                vertical-align: middle;
                overflow: auto;
                padding: 30px;
                background-color: rgba(224, 228, 255, 0.9);
                display: none;
                z-index: 1000;
                width: 100%;
                height: calc(100vh - 90px);
                position: absolute;
                left: 0;
                right: 0;
                top: 90px;
    }

        .Navbar-list li{
            margin-bottom: 40px;
            text-align: center;
        }

        .Navbar-list li :last-child{
            margin-bottom: 40px;
        }

                .popin {
                    display: block;
                    -webkit-animation-name: popin;
                    -moz-animation-name: popin;
                    -ms-animation-name: popin;
                    animation-name: popin;
                    -webkit-animation-duration: 275ms;
                    -moz-animation-duration: 275ms;
                    -ms-animation-duration: 275ms;
                    animation-duration: 275ms;
                    -webkit-animation-timing-function: ease-in-out;
                    -moz-animation-timing-function: ease-in-out;
                    -ms-animation-timing-function: ease-in-out;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-fill-mode: forwards;
                    -moz-animation-fill-mode: forwards;
                    -ms-animation-fill-mode: forwards;
                    animation-fill-mode: forwards;
                    -webkit-transform-origin: top center;
                    -moz-transform-origin: top center;
                    -ms-transform-origin: top center;
                    transform-origin: top center
                }
        
        
                @-webkit-keyframes popin {
                    0% {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0)
                    }
        
                    50% {
                        -webkit-transform: scale(1.05);
                        transform: scale(1.05)
                    }
        
                    100% {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1)
                    }
                }
        
                @-moz-keyframes popin {
                    0% {
                        opacity: 0;
                        transform: scale(0)
                    }
        
                    50% {
                        transform: scale(1.05)
                    }
        
                    100% {
                        opacity: 1;
                        transform: scale(1)
                    }
                }
        
                @keyframes popin {
                    0% {
                        opacity: 0;
                        transform: scale(0)
                    }
        
                    50% {
                        transform: scale(1.05)
                    }
        
                    100% {
                        opacity: 1;
                        transform: scale(1)
                    }
                }
       
}


@media all and (max-width:450px) {
    nav {
        padding: 20px 30px;
    }
}


    