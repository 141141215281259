

.portfolio-banner{
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    /* padding: 50px 80px 0px 80px; */
    align-items: center;
}

.p-left h2{
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 700;
}

.p-left p{
     font-size: 24px;
     /* font-weight: 500; */
}

.p-right img{
    width: 100%;
}

.p-left{
    width: 50%;
}
.p-right{
    width: 50%;
}

.p-left h2 span{
    color: var(--primary-color);
}


@media all and (max-width:1300px){
    .portfolio-banner{
        padding: 50px 60px 0px 60px;
    }
}

@media all and (max-width:960px){
    .portfolio-banner{
        flex-direction: column-reverse;
        gap: 50px;
    }
    .p-left{
        width: 100%;
    }
   
}

@media all and (max-width:768px){
    .p-right {
        width: 70%;
    }
}

@media all and (max-width:450px){
        .portfolio-banner {
            padding: 50px 30px 0px 30px;
        }

        .p-left h2 {
            font-size: 24px;
        }

        .p-left p{
            font-size: 20px;
        }

}
