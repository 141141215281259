

.H-service-container{
   margin-top: 50px;
   max-width: 1280px;
   margin: 0 auto;
   margin-bottom: 50px;
 
}



.H-service-container h6{
    font-size: 20px;
    text-align: center;
    width: 144px;
    margin: 0 auto;
    position: relative;
}

.H-service-container h6::before{
    content: '';
    /* border-left: 1px solid var(--primary-color); */
    position: absolute;
    top: 14px;
    background-color: var(--primary-color);
    left: -20px;
    width: 42px;
    height: 2px;
    transform: rotate(90deg);
}

.H-service-container h2{
   font-size: 30px;
   margin-top: 20px;
   text-align: center;
   font-weight: 700;
}

.service-img-container img{
    width: 100%;
}


.service-img-container{
    display: flex;
    gap: 30px;
    margin-top: 50px;
}

.h-read-more{
    text-align: center;
    margin-top: 40px;
}

.service-img-child{
    /* flex-grow: 1; */
}

@media all and (max-width:1300px){
    .H-service-container {
        padding: 0px 60px;
    }
}

@media all and (max-width:768px){
    .service-img-container{
        display: grid;
        grid-template-columns: repeat(2 ,1fr);
    }
}

@media all and (max-width:450px) {
    .H-service-container {
        padding: 0px 30px;
    }

    .H-service-container h2 {
        font-size: 22px;
        margin-top: 25px;
    }
    .service-img-container {
        gap: 20px;
    }
}