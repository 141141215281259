
.testimonial-in-wrap{
    background-color: black;
    padding: 40px 0px;
    margin-bottom: 70px;
}

.testimonial{
    min-height: 300px;
    max-width: 1280px;
    background-color: black;
    margin: 0 auto;
    
}

.slide-card-img-container{
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    width: 300px;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 42%) !important;
}

.swiper-pagination {
    /* position: absolute; */
    position: static !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal{
    margin-top: 18px !important;
}

.swiper-pagination-bullet{
    width: 10px !important;
    height: 10px !important;
}

.Slide-Card{
    /* height: 300px; */
    padding: 30px 10px 40px 26px;
}

.slide-card-img-container img{
    width: 100%;
    height: 100%;
}

.Testimonial-head{
    font-size: 30px;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
}



.Slide-Card{
    display: flex;
    gap: 20px;
}

.slider-bottom{
    text-align: start;
}

.swiper-button-prev:after,
.swiper-button-next:after {
   font-size: 20px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 10px);
    /* border: 1px solid red; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
}

.swiper-button-prev,
.swiper-button-next{
    -webkit-box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
        box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: white;
}

.swiper-backface-hidden .swiper-slide {
    
   
    border-radius: 4px;
}



.slider-bottom h5{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    background-color: var(--primary-color);
}

.slider-bottom h3{
   font-size: 24px;
   font-weight: 500;
   margin-bottom: 8px;
}

.slider-bottom p{
    
}

.Testimonial-para{
    font-size: 30px;
    color: white;
    font-weight: 400;
    margin-bottom: 50px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    /* display: block; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media all and (max-width:1300px){
    .testimonial{
        padding: 0px 60px;
    }
}

@media all and (max-width:450px){
    .testimonial{
        padding: 0px 30px;
    }

        .slide-card-img-container {
            width: 112px;
        }

        .slider-bottom p{
            text-align: center;
                min-height: 217px;
        }

        .swiper-slide{
            flex-direction: column;
        }

        .slider-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .Testimonial-head{
            font-size: 24px;
        }

        .Testimonial-para{
            font-size: 20px;
        }

        .slider-bottom h3{
            font-size: 22px;
        }
        .slider-bottom h5{
            font-size: 22px;
        }
}