

.a-body-container{
    padding-top: 70px;
    max-width: 1280px;
    margin: 0 auto;
}

.a-body-top{
    max-width: 1038px;
    margin: 0 auto;
    text-align: center;
}

.a-body-top h1{
    margin-bottom: 35px;
    font-weight: 700;
    font-size: 30px;
}

.a-body-top p{
    line-height: 36px;
    font-weight: 500;
    font-size: 20px;
}

.a-body-bottom{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 70px;
    align-items: center;
}

.a-body-bottom :nth-child(2){
    max-width: 706px;
}

.a-body-bottom h2{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.a-body-bottom p{
    font-size: 20px;
    font-weight: 500;
     line-height: 36px;
}

.a-body-img-container{
   max-width: 542px;
}

.a-body-img-container img{
    width: 100%;
}


@media all and (max-width:1300px){
    .a-body-bottom{
        padding: 0px 60px;
        padding-top: 70px;
    }

    .a-body-top{
        padding: 0px 60px;
    }
}

@media all and (max-width:960px){
    .a-body-bottom{
        flex-direction: column;
        gap: 60px;
    }
}

@media all and (max-width:450px) {
    .a-body-bottom {
        padding: 0px 30px;
        padding-top: 70px;
    }

    .a-body-top {
        padding: 0px 30px;
    }

    .a-body-top h1 {
        font-weight: 700;
        font-size: 24px;
    }

    .a-body-bottom h2{
        font-size: 24px;
    }

    .a-footer-container h1{
        font-size: 26px;
    }
}