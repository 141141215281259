

.contact-container{
    padding: 70px 0px;
    max-width: 1280px;
    margin:  0 auto;
    display: flex;
    align-items: center;
    gap: 60px;
    color: white;
}

.contact-left{
    background-image: url('../../assets/contactImg.png');
    background-size: cover;
    border-radius: 5px;
    background-position: center;
    padding: 40px;
    max-width: 530px;
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    
}

.custom-asterisk{
    color: red;
}

.contact-right{
    /* border: 1px solid red; */
    width: calc(100% - 530px);
}

.c-form-title{
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 50px;
}

.contact-top h3{
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 8px;
}

.contact-top p{
    color: rgba(237, 237, 237, 1);
    font-size: 20px;
    font-weight: 300;
}

.contact-bottom-child a{
    color: white;
    font-size: 20px;
    text-decoration: none;
}


.contact-bottom{
    margin-top: 74px;
    display: flex;
    flex-direction: column;
    gap: 46px;
}

.contact-bottom-child{
    display: flex;
    align-items: center;
    gap: 20px;
}


.contact-icon{
    font-size: 30px;
    min-width: 30px;
}

.Contact-btn{
    text-align: end;
    margin-top: 60px;
}

@media all and (max-width:1300px){
    .contact-container{
        padding: 70px 60px;
    }

    .contact-left{
        width: 50%;
    }

    .contact-right{
        width: 50%;
    }
}

@media all and (max-width:768px){
    .contact-container {
           flex-direction: column;
     }

     .contact-bottom{
        margin-top: 70px;
     }

         .contact-left {
            max-width: none;
             width: 100%;
             min-height: 500px;
         }
    
         .contact-right {
             width: 100%;
         }
}



@media all and (max-width:450px) {
    .contact-container {
        padding: 70px 30px;
    }
    .c-form-title{
        font-size: 24px;
        margin-bottom: 30px;
    }
}

@media all and (max-width:400px){
    .contact-left{
        padding-left: 16px;
    }
}