

.a-banner-container{

    max-width: 1280px;
    padding-top: 50px;
    margin: 0 auto;
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    align-items: center;
}

.a-left-part{
    width: 50%;
}

.a-left-part h2{
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 700;
}

.a-left-part h2 span{
    color: var(--primary-color);
}

.a-left-part p{
    font-size: 24px;
    line-height: 45px;
    /* font-weight: 500; */
}

.a-right-part img{
    width: 100%;
}

.a-right-part{
    max-width: 588px;
}



@media all and (max-width:1300px){
    .a-banner-container{
        padding: 0px 60px;
        padding-top: 50px;
    }
}

@media all and (max-width:960px){
    .a-banner-container{
        flex-direction: column-reverse;
        gap: 50px;
    }
        .a-left-part {
            width: 100%;
        }
}


@media all and (max-width:450px) {
    .a-banner-container {
        padding: 0px 30px;
        padding-top: 50px;
    }
        .a-right-part {
            max-width: none;
            width: 70%;
        }

    .a-left-part h2 {
        font-size: 24px;
    }

    .a-left-part p {
        line-height: 30px;
        font-size: 20px;
    }

    
}