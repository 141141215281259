

.banner-container{
    max-width: 1280px;
    padding-top: 50px;
    margin: 0 auto;
}

.content-wrap{
    display: flex;
    /* align-items: center; */
    gap: 40px;
   
}

.banner-container-right{
    display: flex;
    margin-top: 34px;
    max-width: 738px;
    flex-direction: column;
    gap: 30px;
}

.bName{
    color: var(--primary-color);
}

.banner-container-right button{
   max-width: 200px;
   width: 100%;
}

.banner-container-right h1{
    font-size: 40px;
    font-weight: 700;
}  

.banner-container-right h2{
    font-weight:700;
    font-size: 30px;
}

.client-img-container{
    border-radius: 50%;
    overflow: hidden;
    max-width: 150px;
}

.client-img-container img{
    display: block;
    width: 100%;
    height: 100%;
}


.client-card{
text-decoration: none;
    color: black;
}


.client-name{
    text-align: center;
    margin-top: 8px;
    font-size: 18px;
   
    font-weight: 500;
}

.banner-container-right h4{
    font-size: 24px;
    /* font-weight: 500; */
}

.client-container{
    /* padding: 50px 80px;  */

    padding: 50px 0px;
    
}

.trusted-by{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
}

 .banner-img-container img{
    width: 100%;
 }

.Client-img-container{
    margin: 0 auto;
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1078px;
}



@media all and (max-width:1300px){
    .content-wrap{
        padding: 0px 60px 0px 60px;
    }

    .client-container{
        padding: 50px 60px;
        padding-top: 70px;
    }

    .content-wrap{
        align-items: center;
    }

    .banner-container-right{
        width: 50%;
    }

    .banner-img-container{
        width: 50%;
    }
}

@media all and (max-width:1020px){
    .content-wrap{
        flex-direction: column-reverse;
    }

        .banner-container-right {
            width: 100%;
        }
    
        .banner-img-container {
            width:auto;
        }
}


@media all and (max-width:450px){
    .content-wrap {
        padding: 0px 30px 0px 30px;
    }
    .banner-img-container {
        
        width: 70%;
    }

    .banner-container-right h1 {
        font-size: 22px;
    }
    .banner-container-right h2 {
        font-size: 26px;
    }

    .banner-container-right h4 {
        font-size: 20px;
    }

   .trusted-by{
        font-size: 24px;
   }

}