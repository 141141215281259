

.a-bottom{
    background-color: black;
    padding: 40px 0px;

}
.a-bottom-inside{

   color: white;
   text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.a-bottom-sponsifyr{
    font-weight: 600;
    font-size: 22px;
}

.a-bottom-inside h2{
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.a-bottom-inside h3{
   font-weight: 700;
   margin-bottom: 20px;
   font-size: 30px;
}

.a-bottom-inside p{
   line-height: 33px;
   font-size: 20px;
}


@media all and (max-width:768px){
    .a-bottom{
        padding: 40px 60px;
    }
}

@media all and (max-width:450px){
    .a-bottom{
        padding: 40px 30px;
    }

    .a-bottom-inside h3{
        font-size: 24px
    }
}
