
.home-about-us{
      background-color: black;
     
}

.home-content-wrapper{
        background-color: black;
        padding: 30px 0px;
        margin: 0 auto;
        max-width: 1280px;
        margin-top: 50px;
}

.top-section{
    display: flex;
    /* justify-content: space-between; */
    gap: 128px;
    align-items: center;
}

.about-us-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vision-para{
    line-height: 28px;
    /* font-weight: 500; */
}

.top-section-right{
    max-width: 496px;
    width: 100%;
    color: white;
}

.top-section-right h6{
    font-size: 20px;
}

.top-section-right h3{
    font-size: 30px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 20px;
}

.progress-section{
    margin-top: 20px;
}

.progress-section label{
    color: white;
    font-size: 20px;
}

.progress{
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    height: 14px;
}

.progress-value{
    height: 100%;
    transition: width 2s ease;
    background-color: var(--primary-color);
}


.bottom-section{
    display: flex;
    color: white;
    gap: 68px;
    margin-top: 40px;
}

.bottom-section-left{
    max-width: 622px;   
}

.bottom-section-left h2{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.bottom-section-right h2{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.bottom-section-right div{
    display: flex;
    gap: 30px;
}

.our-v-card{
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.our-v-card p{
    text-align: center;
}

.our-v-card img{
    width: 68px;
}



@media all and (max-width:1300px) {
    .home-content-wrapper {
        padding: 30px 60px;
    }
}


@media all and (max-width:1020px){

    .about-us-img-container{
        width: 50%;
        height: 500px;
    }

    .top-section-right{
        width: 50%;
    }

    .bottom-section-left{
        width: 50%;
        
    }

    .top-section{
        gap: 50px;
    }

    .bottom-section-right{
        width: 50%;
    }
}

@media all and (max-width:768px){
    .about-us-img-container {
        width: 100%;
        height: auto;
    }
    
    .top-section-right {
        max-width: none;
        width: 100%;
    }

    .bottom-section-left {
        width: 100%;
    }

    .top-section {
        gap: 50px;
        flex-direction: column;
    }

    .bottom-section{
        flex-direction: column;
    }

    .bottom-section-right {
        width: 100%;
    }
}

@media all and (max-width:450px){
    .home-content-wrapper {
        padding: 30px 30px;
    }
   
    .top-section-right h3 {
        font-size: 22px;
    }
        
    .progress-section label {
        font-size: 18px;
    }
    .our-v-card p {
        max-width: 200px;
    }

    .bottom-section-left h2 ,.bottom-section-right h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 30px;
    }
}


@media all and (max-width:380px){
    .bottom-section-right div {
        flex-wrap: wrap;
        justify-content: center;
    }
}