@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root{
   --primary-color:#5c14dd;
   --black-color:#000000;
   --MainHeadingPara-fontSize:40px;
   --MainHeadingPara-fontWeight:600;
   --secondaryHeadingPara-fontWeight:500;
   --finalHeadingPara-fontWeight:500;
   --secondaryHeadingPara-font:30px;
   --contentPara-font:20px;
   --miniPara-font:16px;
   
}

::-webkit-scrollbar {
   width: 18px;
}

::-webkit-scrollbar-track {
   background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
   background-color: rgb(157, 173, 245);
   border-radius: 20px;
   transition: all 500ms;
   border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
   background: rgb(172, 182, 227);
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

body{
   font-family: "Poppins", sans-serif;
   overflow-x: hidden !important;
}

body , html{
   overflow-x: hidden !important;
}

h1,h2,h3,h4,h5,h6{
   font-size: 16px;
   font-weight: normal;

}



