

.s-banner-container{
   margin: 0 auto;
   padding-top: 50px;
   max-width: 1280px;
   justify-content: space-between;
   display: flex;
   gap: 50px;
   align-items: center;
}


.s-banner-right img{
   width: 100%;
   height: 100%;
}

.s-banner-left h1{
   font-size: 40px;
   font-weight: 700;
   line-height: 43.52px;
   margin-bottom: 24px;
}

.s-banner-left p{
   font-size: 24px;
   /* font-weight: 500; */
   line-height: 45px;
}

.s-banner-left{
   max-width: calc(100% - 600px);
   
}

.s-banner-right{
   max-width: 600px;
}

@media all and (max-width:1300px){
   .s-banner-container{
      padding: 0px 60px;
      padding-top: 50px;
   }

   .s-banner-right{
      max-width: none;
      width: 50%;
   }

   .s-banner-left{
      max-width: none;
      width: 50%;
   }
}

@media all and (max-width:960px){
   .s-banner-container {
         flex-direction: column-reverse;
   }

      .s-banner-right {
         max-width: none;
         width: 60%;
      }
   
      .s-banner-left {
         max-width: none;
         width: 100%;
      }
}

@media all and (max-width:450px){
   .s-banner-container {
         padding: 0px 30px;
         padding-top: 50px;
    }


   .s-banner-right{
      width: 70%;
   }

   .s-banner-left h1{
      font-size: 24px;
   }
      .s-banner-left p {
         font-size: 20px;
         line-height: 30px;
      }
   
}