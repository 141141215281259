

.p-inside-wrapper{
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 70px;
}



.p-main-title{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
}

.p-card-title{
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 700;
}

.p-card-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.p-cards-container {
    position: relative;
    border-radius: 4px
}


.p-card-img-container{
    height: 100%;
}

.p-card-img-container img{
   width: 100%;
   height: 100%;
   border-radius: 4px;
}

/* .p-description button{
    font-size: 30px;
} */

.p-description h2{
    font-size: 30px;
}

.p-cards-container:hover .p-description {
    background-color: rgba(0, 0, 0, 0.3);
}

.p-cards-container:hover .p-description .play-button {
    opacity: 1;
    top: 35%
}


.p-cards-container:hover .p-description h2 {
    opacity: 1;
    top: 50%
}



.p-description{
    /* border: 1px solid red; */
    position: absolute;
    top: 0;
    /* background-color: red; */
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color:rgb(144, 143, 143); */
    color: #fff;
    transition: all .5s ease-in-out;
}

.p-description:hover{
    background-color: rgba(0, 0, 0, 0.3);
}

.Play-icon:hover{
    transform: scale(1.2);
}

.Play-icon{
    transition: all .5s ease-in-out
}

.play-button{
    cursor: pointer;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translatex(-50%);
    opacity: 0;
    transition: all .7s ease-in-out
}

.p-description h2{
        position: absolute;
        margin: auto;
        top: 90%;
        text-align: center;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all 1s ease-in-out;
        font-size: 22px;
        text-transform: uppercase;
}

.p-description p {
    position: absolute;
    top: 100%;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 18px;
    width: 90%;
    margin: auto;
    opacity: 0;
    transition: all 1.5s;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}

.ExploreBtn{
    margin-top: 40px;
    display: flex;
    text-decoration: none;
    justify-content: center;
}


.p-youTube-section{
    margin-top: 70px;
}

.p-podCast-section{
    margin-top: 70px;
}

@media all and (max-width:1200px){
.p-inside-wrapper {
        padding: 70px 60px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {



  .p-cards-container:hover .p-description .play-button {
        opacity: 1;
        top: 35%
    }

    .play-button{
        font-size: 40px !important;
    }


    .p-cards-container:hover .p-description h2 {
        opacity: 1;
        top: 60%
    }
}

@media (min-width:450px) and (max-width: 630px){
    .p-cards-container:hover .p-description .play-button {
            opacity: 1;
            top: 35%
        }
    
        .play-button {
            font-size: 40px !important;
        }
    
    
        .p-cards-container:hover .p-description h2 {
            opacity: 1;
            top: 55%
        }
}


@media all and (max-width:768px){

    .p-card-container{
       grid-template-columns: repeat(2, 1fr);
    }

}


@media all and (max-width:450px){

    .play-button {
       font-size: 30px !important;
    }

         .p-cards-container:hover .p-description h2 {
             opacity: 1;
             top: 56%
         }

    .p-description h2{
        font-size: 16px;
    }

    .p-inside-wrapper {
        padding: 70px 30px;
    }
        .p-main-title {
           font-size: 24px
        }

        .p-card-title {
            font-size: 20px;
            margin-bottom: 24px;
        }
}




