

.solution-container{
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 70px;
}

.solution-para{
   max-width: 1126px;
   margin: 0 auto;
   margin-bottom: 70px;
   text-align: center;
   font-size: 25px;
   font-weight: 500;
}

.solution-para span{
    font-weight: 700;
    color: var(--primary-color);
}

.solution-main{
    display: flex;
    align-items: center;
    gap: 60px;
}


.solution-left{
    width: 50%;
}

.solution-right{
    width: 50%;
}

.solution-right h6{
   font-size: 30px;
   position: relative;
   margin-bottom: 15px;
}

.solution-right p{
     font-size: 20px;
}

.solution-right h6::before{
    content:'';
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: var(--primary-color);
    left: -34px;
    top: 20px;
    transform: rotate(90deg);
}

.solution-right h2{
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 30px;
}


.solution-child-inside{
    display: flex;
    align-items: center;
    gap: 30px;
}

.solution-child-container{
     display: flex;
     flex-direction: column;
     margin-top: 30px;
     gap: 50px;
}

.solution-icon{
    color: var(--primary-color);
    font-size: 40px;
    display: flex;
    padding: 10px ;
    background-color: rgba(247, 247, 247, 1);
    border-radius: 20px;
}

.child-content h1{
    font-size: 30px;
    font-weight: 700;
}

.child-content p{
    font-size: 20px;
    font-weight: 500;
}


@media all and (max-width:1300px){
    .solution-container {
          padding: 0px 60px;
    }
    
}

@media all and (max-width:960px){
    .solution-main{
          flex-direction: column;
    }
    
   .solution-right p {
       font-size: 18px;
   }

    .solution-left{
        width: 60%;
    }

    .solution-right{
        width: 100%;
    }
}

@media all and (max-width:450px){
    .solution-container {
        padding: 0px 30px;
    }

    .solution-para{
        font-size: 20px;
    }

    .solution-left {
        width: 100%;
    }

  .solution-right h6 {
      font-size: 26px;
  }

  .solution-right h2 {
      font-size: 24px;
  }

  .solution-icon{
    font-size: 34px;
  }
  .child-content h1 {
      font-size: 24px;
  }

}