
.Wrapper{
  position: relative;
}

.ScrollBtn{
  position: fixed;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 22;
  right: -60px;
  bottom: 50px;
  transition: all 0.3s ease;
  border: none;
  color: white;
  font-size: 30px;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}


.whtAnimate{
    animation: bounce 1s infinite;
}

.whtAnimate:hover{
  background-color: #40d978 !important;
}


.ScrollBtnToHide{
   right: 60px;
}


.moveIcon{
  position:absolute;
  top: 50%;
  transform: translate(-50% , -50%);
  left: 50%;
}

.ScrollBtn:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
}

.ScrollBtn:active{
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); 
}


@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}



.ScrollBtn:hover {
  animation: bounce 1s infinite;
}


@media all and (max-width:450px){
  .ScrollBtn{
    bottom: 24px;
    font-size: 22px;
    width: 40px;
    height: 40px;
  }

  .whtAnimate{
    right: 10px !important;
    bottom: 90px !important;
    font-size: 13px !important;
    letter-spacing: 1px !important;
  }

    .ScrollBtnToHide {
      right: 54px;
    }
}



.splash-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* or your preferred background color */
  z-index: 9999;
}

.logo {
  width: 100
  px;
  /* adjust size as needed */
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
