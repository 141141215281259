

.h-footer{
    max-width: 732px;
    display: flex;
    padding-bottom: 70px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
}


.h-footer h1{
  font-size: 40px;
  font-weight: 700;
}

.h-footer h2{
    font-size: 30px;
    font-weight: 700;
}

.h-footer p{
    font-size: 18px;
    font-weight: 500;
}


@media all and (max-width:1300px){
    .h-footer{
        padding: 0px 60px;
        padding-bottom: 60px;
    }
}

@media all and (max-width:450px){
    .h-footer{
        padding: 0px 30px;
        padding-bottom: 60px;
    }

    .h-footer h2{
        font-size: 24px;
    }
    .h-footer h1{
        font-size: 20px;
    }
}